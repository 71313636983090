import { RoleDto } from '@nstep-common/core';
import { JsonProperty } from '@nstep-common/utils';

export class NationDto {
	@JsonProperty({ type: Number })
	id!: number;

	@JsonProperty({ type: String })
	nationName!: string;
}

export class OperatorCardDto {
	@JsonProperty({ type: Number })
	id!: number;

	@JsonProperty({ type: String })
	cardNumber!: string;
}

export class HeadquarterWithNationDto {
	@JsonProperty({ type: Number })
	id!: number;

	@JsonProperty({ type: String })
	name!: string;

	@JsonProperty({ type: Number })
	nationId!: number;

	constructor(data: Partial<HeadquarterWithNationDto> | null = null) {
		if (data) {
			Object.assign(this, data);
		}
	}
}

export class SubRoleDto {
	@JsonProperty({ type: Number })
	id!: number;

	@JsonProperty({ type: Number })
	roleId!: number;

	@JsonProperty({ type: String, nullable: true })
	name!: string | null;

	constructor(data: Partial<SubRoleDto> | null = null) {
		if (data) {
			Object.assign(this, data);
		}
	}
}

export class UsersInSubRole {
	@JsonProperty({ type: Number })
	id!: number;

	@JsonProperty({ type: Number })
	userId!: number;

	@JsonProperty({ type: Number })
	subRoleId!: number;
}

export class UserLookupDto {
	@JsonProperty({ type: Number })
	id!: number;

	@JsonProperty({ type: String })
	userName!: string;

	constructor(data: Partial<UserLookupDto> | null = null) {
		if (data) {
			Object.assign(this, data);
		}
	}
}

export class UserDto {

	@JsonProperty({ type: Number, nullable: true })
	id: number | null = null;

	@JsonProperty({ type: String })
	userName!: string;

	@JsonProperty({ type: String, nullable: true })
	password: string | null = null;

	@JsonProperty({ type: String, nullable: true })
	name!: string | null;

	@JsonProperty({ type: String, nullable: true })
	email!: string | null;

	@JsonProperty({ type: String, nullable: true })
	telephone!: string | null;

	@JsonProperty({ type: String, nullable: true })
	other!: string | null;

	@JsonProperty({ type: Date, nullable: true })
	startDate: Date | null = null;

	@JsonProperty({ type: Date, nullable: true })
	endDate: Date | null = null;

	@JsonProperty({ type: Number, nullable: true })
	nationId!: number | null;

	@JsonProperty({ type: Boolean })
	resetted!: boolean;

	@JsonProperty({ type: Number, nullable: true })
	cardOperatorId!: number | null;

	@JsonProperty({ type: String, nullable: true })
	personId!: string | null;

	@JsonProperty({ type: Boolean })
	requires2F!: boolean;

	@JsonProperty({ type: Boolean })
	isHardLocked!: boolean;

	@JsonProperty({ type: NationDto, nullable: true })
	nation!: NationDto | null;

	@JsonProperty({ type: RoleDto })
	role!: RoleDto;

	@JsonProperty({ type: OperatorCardDto, nullable: true })
	card!: OperatorCardDto | null;

	@JsonProperty({ type: HeadquarterWithNationDto, array: true })
	headquarters!: HeadquarterWithNationDto[];

	@JsonProperty({ type: SubRoleDto, array: true })
	subRoles!: SubRoleDto[];

	constructor(data: Partial<UserDto> | null = null) {
		if (data) {
			Object.assign(this, data);
		}
	}
}

export class NationWithHqDtos extends NationDto {
	@JsonProperty({ type: Number })
	timezoneOffset!: number;

	@JsonProperty({ type: HeadquarterWithNationDto, array: true })
	headquarters!: HeadquarterWithNationDto[];
}

export class UserModel {
	id: number | null = null;
	roleId: number | null = null;
	userName: string | null = null;
	password: string | null = null;
	name: string | null = null;
	email: string | null = null;
	telephone: string | null = null;
	other: string | null = null;
	startDate: Date | null = null;
	endDate: Date | null = null;
	nationId: number | null = null;
	resetted: boolean = false;
	cardOperatorId: number | null = null;
	cardOperatorNumber: string | null = null;
	headquartersIds: number[] = [];
	subRolesIds: number[] = [];

	constructor(data: Partial<UserModel> | null = null) {
		if (data) {
			Object.assign(this, data);
		}
	}
}

export class Toggle2FModel {
	userId!: number;
	requires2F!: boolean;

	constructor(data: Partial<Toggle2FModel> | null = null) {
		if (data) {
			Object.assign(this, data);
		}
	}
}

export class ToggleLockModel {
	userId!: number;
	isHardLocked!: boolean;

	constructor(data: Partial<ToggleLockModel> | null = null) {
		if (data) {
			Object.assign(this, data);
		}
	}
}

export class DisableUserModel {
	userId!: number;
	endDate!: Date | null;
}

export class ChangeUserPasswordModel {
	userId!: number;
	password!: string | null;
	email!: string | null;
}

export enum UserRoles {
	Administrator = 1,
	User = 2,
	HostNation = 3,
	Operator = 4,
	MatrixAdministrator = 5,
	BUSAdministrator = 6,
	ITAdministrator = 7,
	Device = 8
}

