import { Component, OnInit, ViewChild } from '@angular/core';

import { createProxy, toast } from '@nstep-common/utils';
import { BaseComponent, ColumnSortation, PagedQueryParameter, PaginationModel, TableColumn } from '@nstep-common/core';
import { ModalComponent } from '@nstep-common/semantic-ui';

import { ValidationErrors } from 'fluentvalidation-ts';
import { chain, flatten } from 'lodash';
import { EntitlementUnitModel, EntitlementUnitModelValidator } from '@nstep-internal/pages';
import { EntitlementUnitService } from '@nstep-internal/shared';

@Component({
	selector: 'app-entitlement-units',
	templateUrl: './entitlement-units.component.html'
})
export class EntitlementUnitsComponent extends BaseComponent implements OnInit {

	@ViewChild('entitlementUnitsModal') entitlementUnitsModal!: ModalComponent;
	@ViewChild('entitlementUnitConfimationModal') entitlementUnitConfimationModal!: ModalComponent;

	tableData: any[] = [];
	tableDataReady = false;

	editModal: boolean = false;
	action: string = '';

	modalIsLoading = false;

	errors: string[] = [];

	tableColumns: TableColumn[] = [
		{ name: 'Name', key: 'name', sortAsc: true, isCellCentered: true, isHeaderCentered: true },
		{ name: 'Action', isCellCentered: true, isHeaderCentered: true }
	];

	pagedQueryModel = new PagedQueryParameter({
		itemsPerPage: 10,
		page: 1,
		orderField: 'name',
		searchBy: '',
		isMultiWordSerch: false
	});

	pagination = new PaginationModel();

	validation: ValidationErrors<EntitlementUnitModel> = {};
	isValid: boolean = false;

	entitlementUnit: EntitlementUnitModel = createProxy(new EntitlementUnitModel(), {
		set: () => this.validate(this.entitlementUnit)
	});

	validate(value: EntitlementUnitModel): void {
		const validator = new EntitlementUnitModelValidator();
		this.validation = validator.validate(value);
		this.isValid = Object.keys(this.validation).length === 0;
	}

	constructor(private entitlementUnitService: EntitlementUnitService) {
		super();
	}

	ngOnInit(): void {
		super.ngOnInit();

		this.initializeTable();
	}

	initializeTable(): void {
		this.subscriptions.push(
			this.entitlementUnitService.getEntitlementUnits(this.pagedQueryModel).subscribe({
				next: response => {

					this.pagination = response.page;
					this.tableData = chain(response.results)
						.map(e => new EntitlementUnitModel({
							id: e.id,
							name: e.name,
							disabled: e.disabled,
							action: e.disabled ? 'Enable' : 'Disable'
						}))
						.value();

					this.tableDataReady = true;
					this.modalIsLoading = false;
				},
				error: () => {
					this.tableDataReady = true;
					this.modalIsLoading = false;
				}
			})
		);
	}

	openAddUnitModal(): void {
		this.editModal = false;
		this.entitlementUnit = createProxy(new EntitlementUnitModel(), {
			set: () => this.validate(this.entitlementUnit)
		});

		this.validate(this.entitlementUnit);

		this.entitlementUnitsModal.toggle();
	}

	search(): void {
		this.pagedQueryModel.page = 1;
		this.initializeTable();
	}

	onUserAction(item: any, action: string): void {
		this.editModal = false;
		this.action = action;

		const selectedItem = new EntitlementUnitModel({
			id: item.id,
			name: item.name,
			disabled: item.disabled
		});

		this.entitlementUnit = createProxy(selectedItem, {
			set: () => this.validate(this.entitlementUnit)
		});

		if (this.action === 'Edit') {
			this.editModal = true;
			this.validate(this.entitlementUnit);
			this.entitlementUnitsModal.toggle();

			return;
		}

		this.entitlementUnitConfimationModal.toggle();
	}

	close(modal: string): void {
		switch (modal) {
			case 'entitlementUnitsModal':
				this.entitlementUnitsModal.toggle();
				break;
			case 'entitlementUnitConfimationModal':
				this.entitlementUnitConfimationModal.toggle();
				break;
		}

		this.errors = [];
	}

	save(): void {
		this.errors = [];
		this.modalIsLoading = true;


		if (this.editModal) {
			this.subscriptions.push(
				this.entitlementUnitService.updateEntitlementUnit(this.entitlementUnit).subscribe({
					next: () => {
						this.tableDataReady = false;
						this.tableData = [];

						this.entitlementUnitService.clearGetEntitlementUnitsCache();
						this.initializeTable();

						toast('Success', `Entitlement Unit ${this.entitlementUnit.name} successfully modified!`, 'green');
						this.entitlementUnitsModal.toggle();
					},
					error: (response) => {
						this.modalIsLoading = false;

						toast('Error', `Entitlement Unit ${this.entitlementUnit.name} could not be modified!`, 'red');
						this.errors = flatten(Object.values(response));
					}
				})
			);
		} else {
			this.subscriptions.push(
				this.entitlementUnitService.createEntitlementUnit(this.entitlementUnit).subscribe({
					next: () => {
						this.tableDataReady = false;
						this.tableData = [];

						this.entitlementUnitService.clearGetEntitlementUnitsCache();
						this.initializeTable();

						toast('Success', `Entitlement Unit ${this.entitlementUnit.name} saved!`, 'green');
						this.entitlementUnitsModal.toggle();
					},
					error: (response) => {
						this.modalIsLoading = false;

						toast('Error', `Entitlement Unit ${this.entitlementUnit.name} could not be saved!`, 'red');
						this.errors = flatten(Object.values(response));
					}
				})
			);
		}
	}

	enableOrDisable(): void {
		this.errors = [];
		this.modalIsLoading = true;

		if (this.action === 'Enable') {
			this.subscriptions.push(
				this.entitlementUnitService.enableEntitlementUnit(this.entitlementUnit.id).subscribe({
					next: () => {
						this.tableDataReady = false;
						this.tableData = [];

						this.entitlementUnitService.clearGetEntitlementUnitsCache();
						this.initializeTable();

						toast('Success', `Entitlement Unit ${this.entitlementUnit.name} successfully enabled!`, 'green');
						this.entitlementUnitConfimationModal.toggle();
					},
					error: () => {
						this.modalIsLoading = false;
						toast('Error', `Entitlement Unit ${this.entitlementUnit.name} could not be enabled!`, 'red');
					}
				})
			);
		} else {
			this.subscriptions.push(
				this.entitlementUnitService.disableEntitlementUnit(this.entitlementUnit.id).subscribe({
					next: () => {
						this.tableDataReady = false;
						this.tableData = [];

						this.entitlementUnitService.clearGetEntitlementUnitsCache();
						this.initializeTable();

						toast('Success', `Entitlement Unit ${this.entitlementUnit.name} successfully disabled!`, 'green');
						this.entitlementUnitConfimationModal.toggle();
					},
					error: () => {
						this.modalIsLoading = false;
						toast('Error', `Entitlement Unit ${this.entitlementUnit.name} could not be disabled!`, 'red');
					}
				})
			);
		}
	}

	pageChange(page: PaginationModel): void {
		this.pagedQueryModel.page = page.currentPage;
		this.pagedQueryModel.itemsPerPage = page.pageSize;

		this.tableDataReady = false;
		this.tableData = [];

		this.initializeTable();
	}

	sortChange(col: ColumnSortation): void {
		this.pagedQueryModel.page = 1;
		this.pagedQueryModel.orderField = col.sortAsc !== null ? `${col.key} ${col.sortAsc ? 'asc' : 'desc'}` : '';

		this.tableDataReady = false;
		this.tableData = [];

		this.initializeTable();
	}
}
