import { Component, OnInit, ViewChild } from '@angular/core';
import { BaseComponent, TableColumn } from '@nstep-common/core';
import { DropdownOption, ModalComponent } from '@nstep-common/semantic-ui';
import { createProxy, toast } from '@nstep-common/utils';
import { ClientModel, ClientValidator } from '@nstep-internal/pages';
import { ClientsService, PosLocationService, RolesService } from '@nstep-internal/shared';
import { chain, flatten } from 'lodash';
import { EMPTY, Observable, catchError, finalize, forkJoin, tap } from 'rxjs';

@Component({
	selector: 'app-clients',
	templateUrl: './clients.component.html'
})
export class ClientsComponent extends BaseComponent implements OnInit {
	@ViewChild('upsertModal') upsertModal!: ModalComponent;
	@ViewChild('confirmModal') confirmModal!: ModalComponent;

	tableData: any[] = [];
	tableDataReady = false;

	tableColumns: TableColumn[] = [
		{ name: 'Name', key: 'name', sortAsc: true, isHeaderCentered: true, isCellCentered: true },
		{ name: 'Description', key: 'description', isHeaderCentered: true, isCellCentered: true },
		{ name: 'Client Type', key: 'type', isHeaderCentered: true, isCellCentered: true },
		{ name: 'Valid From', key: 'validFrom', isHeaderCentered: true, isCellCentered: true },
		{ name: 'Valid Until', key: 'validTo', isHeaderCentered: true, isCellCentered: true },
		{ name: 'Enabled', key: 'active', isHeaderCentered: true, isCellCentered: true },
		{ name: 'Actions', isHeaderCentered: true, isCellCentered: true }
	];

	waitingForServer = true;

	client: ClientModel = createProxy(new ClientModel(), {
		set: () => this.validate()
	});

	selectedClient: any = null;

	validation = {};
	isValid = false;

	errors = [];
	confirmMessage = '';

	clientTypes: DropdownOption[] = [
		new DropdownOption({ value: 1, name: 'Library Client' }),
		new DropdownOption({ value: 2, name: 'Business Client' }),
		new DropdownOption({ value: 3, name: 'Internal Client' })
	];

	posLocations: DropdownOption[] = [];

	calendarSettings = {
		type: 'date',
		minDate: new Date()
	};

	state: any = {};
	clientPermissions: any = {};

	constructor(private clientsService: ClientsService,
		private posLocationService: PosLocationService,
		private rolesService: RolesService) {
		super();
	}

	ngOnInit(): void {
		super.ngOnInit();

		this.waitingForServer = true;

		this.subscriptions.push(forkJoin([this.initTable(), this.getPosLocations(), this.getClientApiPermissions()])
			.pipe(finalize(() => {
				this.waitingForServer = false;
			}))
			.subscribe());
	}

	initTable(): Observable<any> {
		this.tableDataReady = false;

		const getRoles$ = this.clientsService
			.getClients()
			.pipe(
				tap(data => {
					this.tableData = chain(data)
						.map(i => ({
							id: i.id,
							name: i.name,
							description: i.description,
							type: i.clientType == 1 ? 'Library Client' : (i.clientType == 2 ? 'Business Client' : 'Internal Client'),
							active: !i.isDisabled,
							validFrom: i.validFrom,
							validTo: i.validTo
						}))
						.value();
				}),
				finalize(() => {
					this.tableDataReady = true;
				})
			);

		return getRoles$;
	}

	getPosLocations(): Observable<any> {
		const getPosLocations$ = this.posLocationService
			.getLocationInfoAssociation()
			.pipe(tap(response => {
				this.posLocations = chain(response)
					.map(l => new DropdownOption({
						value: l.id,
						name: l.name
					}))
					.value();
			}));

		return getPosLocations$;
	}

	getClientApiPermissions(): Observable<any> {
		const getClientApiPermissions$ = this.rolesService
			.getClientApiPermissions()
			.pipe(tap(reponse => {
				this.clientPermissions = reponse;
			}));

		return getClientApiPermissions$;
	}

	validate(): void {
		const validator = new ClientValidator();
		this.validation = validator.validate(this.client);
		this.isValid = Object.keys(this.validation).length === 0;
	}

	createClient(): void {
		this.errors = [];

		this.client = createProxy(new ClientModel(), {
			set: () => this.validate()
		});

		this.validate();

		this.upsertModal.toggle();
	}

	editClient(item: any): void {
		this.errors = [];

		this.subscriptions.push(this.clientsService.getClient(item.id)
			.subscribe(client => {
				this.client = createProxy(new ClientModel(client), {
					set: () => this.validate()
				});

				if (client.type == 2) {
					for (const claim of client.claims) {
						this.state[claim] = true;
					}
				}
				else {
					this.state = [];
				}

				this.validate();
				this.upsertModal.toggle();
			}));
	}

	updateClient(): void {
		this.waitingForServer = true;

		if (this.client.type == 1) {
			this.client.allowUserImpersonation = null;
		}
		else if (this.client.type == 2) {
			this.client.allowUserImpersonation = this.client.allowUserImpersonation ?? false;

			this.client.claims.push(this.clientPermissions.id);
			this.client.claims = chain(Object.keys(this.state))
				.filter(k => this.state[k])
				.value();
		}
		else {
			this.client.allowUserImpersonation = null;
			this.client.posLocationId = null;
		}

		const updateClient$ = (this.client.id
			? this.clientsService.updateClient(this.client)
			: this.clientsService.createClient(this.client))
			.pipe(
				tap(() => {
					this.upsertModal.toggle();
					toast('Success', `Client has been ${this.client.id ? 'updated' : 'created'}.`, 'green');

					this.subscriptions.push(this.initTable().subscribe());
				}),
				catchError(errors => {
					this.errors = flatten(Object.values(errors));
					toast('Error', 'Client could not be updated.', 'red');

					return EMPTY;
				}),
				finalize(() => this.waitingForServer = false)
			)
			.subscribe();

		this.subscriptions.push(updateClient$);
	}

	regenerateClientSecret(): void {
		this.client.clientSecret = crypto.randomUUID();
	}

	confirm(action: string, item?: any): void {
		if (action == 'ask-delete') {
			this.selectedClient = item;

			this.confirmMessage = `Are you sure you want to delete client "${item.name}"?`;
			this.confirmModal.toggle();
		}

		if (action == 'delete-client') {
			this.deleteClient();
		}
	}

	deleteClient() {
		this.waitingForServer = true;

		const deleteClient$ = this.clientsService.deleteClient(this.selectedClient.id)
			.pipe(
				tap(() => {
					this.confirmModal.toggle();
					this.subscriptions.push(this.initTable().subscribe());

					toast('Success', `Client ${this.selectedClient.name} has been deleted.`, 'green');
				}),
				catchError(() => {
					this.confirmModal.toggle();
					toast('Error', `Client ${this.selectedClient.name} could not be deleted.`, 'red');

					return EMPTY;
				}),
				finalize(() => {
					this.waitingForServer = false;
				})
			)
			.subscribe();

		this.subscriptions.push(deleteClient$);
	}

	toggleClient(client: any) {
		this.waitingForServer = true;

		const toggleClient$ = this.clientsService
			.toggleClient(client.id)
			.pipe(
				tap(() => {
					toast('Success', `Client '${client.name}' has been ${client.active ? 'enabled' : 'disabled'}.`, 'green');
				}),
				catchError(() => {
					client.active = !client.active;

					return EMPTY;
				}),
				finalize(() => {
					this.waitingForServer = false;
				})
			)
			.subscribe();

		this.subscriptions.push(toggleClient$);
	}
}
