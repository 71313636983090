import { Injectable } from '@angular/core';
import { ApiService, PagedQueryParameter, PagedResponse, RoleDto } from '@nstep-common/core';
import { DisableUserModel, ChangeUserPasswordModel, SubRoleDto, Toggle2FModel, ToggleLockModel, UserDto, UserLookupDto } from '@nstep-internal/pages';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class UserService {

	constructor(private apiService: ApiService) { }

	getUsers(model: PagedQueryParameter): Observable<PagedResponse<UserDto>> {
		return this.apiService.getPage([UserDto], 'users', model);
	}

	getUsersBy2FEnabled(query: string): Observable<UserLookupDto[]> {
		return this.apiService.get([UserLookupDto], `users/2f-enabled?query=${query}`);
	}

	getUser(id: number): Observable<UserDto> {
		return this.apiService.get(UserDto, `users/${id}`);
	}

	getRoles(): Observable<RoleDto[]> {
		return this.apiService.get([RoleDto], 'users/roles');
	}

	getSubRoles(): Observable<SubRoleDto[]> {
		return this.apiService.get([SubRoleDto], 'users/sub-roles');
	}

	createUser(model: UserDto): Observable<UserDto> {
		return this.apiService.post(UserDto, 'users', model);
	}

	updateUser(model: UserDto): Observable<UserDto> {
		return this.apiService.put(UserDto, 'users', model);
	}

	toggleLock(model: ToggleLockModel): Observable<any> {
		return this.apiService.postNoContent('users/toggle-lock', model);
	}

	toggle2F(model: Toggle2FModel): Observable<any> {
		return this.apiService.postNoContent('users/toggle-2f', model);
	}

	disableUser(model: DisableUserModel): Observable<UserDto> {
		return this.apiService.post(UserDto, 'users/disable', model);
	}

	changeUserPassword(model: ChangeUserPasswordModel): Observable<UserDto> {
		return this.apiService.post(UserDto, 'users/change-password', model);
	}

	reset2F(userId: number): Observable<any> {
		return this.apiService.postNoContent('users/reset-2f', userId);
	}

	clearGetUsersCache(): void {
		this.apiService.invalidateCache('users');
	}

	clearGetUserCache(id: number): void {
		this.apiService.invalidateCache(`users/${id}`);
	}
}
