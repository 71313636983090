import { ExtraValidator } from '@nstep-common/utils';
import { DisableUserModel, ChangeUserPasswordModel, UserLookupDto, UserModel } from '@nstep-internal/pages';
import { PasswordSettingsModel } from '@nstep-common/core';

export class UserModelValidator extends ExtraValidator<UserModel> {
	constructor(settings: PasswordSettingsModel) {
		super();

		this.ruleFor('password')
			.matches(new RegExp('^.{' + settings.MinimumLength + ',' + settings.MaximumLength + '}$'))
			.withMessage(`Length must be between ${settings.MinimumLength}-${settings.MaximumLength} characters!`)
			.matches(new RegExp('^(?:[^\\d]*\\d){' + settings.MinimumNumericCharacters + ',}'))
			.withMessage(`Minimum digits required: ${settings.MinimumNumericCharacters}`)
			.matches(new RegExp('^(?:[^a-z]*[a-z]){' + settings.MinimumLowercaseCharacters + ',}'))
			.withMessage(`Minimum lowercase letters: ${settings.MinimumLowercaseCharacters}`)
			.matches(new RegExp('^(?:[^A-Z]*[A-Z]){' + settings.MinimumUppercaseCharacters + ',}'))
			.withMessage(`Minimum uppercase letters: ${settings.MinimumUppercaseCharacters}`)
			.matches(new RegExp('^(?:[^' + settings.SpecialCharacters.replace('-', '\\-') + ']*[' + settings.SpecialCharacters.replace('-', '\\-') + ']){' + settings.MinimumSpecialCharacters + ',}'))
			.withMessage(`Minimum special characters: ${settings.MinimumSpecialCharacters} from ${settings.SpecialCharacters}`)
			.when(model => model.password !== null);

		this.ruleFor('subRolesIds')
			.must(subRolesIds => !!subRolesIds && subRolesIds.length > 0)
			.withMessage('Sub roles are required')
			.when(model => model.roleId === 4);

		this.ruleFor('userName')
			.must(userName => !!userName)
			.withMessage('Username is required')
			.notEmpty()
			.matches(/^[0-9A-Za-z \-_\.]{3,20}$/)
			.withMessage('A valid input is required with the length between 3-20 characters');

		this.ruleFor('name')
			.must((name: any) => !!name)
			.withMessage('Name is required')
			.notEmpty()
			.matches(/^[0-9A-Za-z\- ]{5,50}$/)
			.withMessage('A valid input is required with the length between 5-50 characters');

		this.ruleFor('email')
			.must(email => !!email)
			.withMessage('Email is required')
			.emailAddress();

		this.ruleFor('telephone')
			.must((telephone: any) => !!telephone)
			.withMessage('Phone number is required')
			.notEmpty()
			//.matches(/^(?=.*\d)[a-zA-Z0-9 _()+]{4,}$/) this is the old validation
			.matches(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/)
			.withMessage('Phone number is not valid');

		this.ruleFor('startDate')
			.must(startDate => !!startDate)
			.withMessage('Start Date is required');

		this.ruleFor('nationId')
			.must(nationId => !!nationId)
			.withMessage('Country is required')
			.when(model => model.roleId === 3 || model.roleId === 5);

		this.ruleFor('cardOperatorNumber')
			.must(cardOperatorNumber => !!cardOperatorNumber)
			.withMessage('AMIS Card Number is required')
			.when(model => model.roleId === 4);

		this.ruleFor('headquartersIds')
			.must(headquartersIds => !headquartersIds || headquartersIds.length > 0)
			.withMessage('Headquarters are required')
			.when(model => model.roleId === 3 || model.roleId === 5);

		this.ruleFor('other')
			.matches(/^.{2,100}$/)
			.withMessage('A valid input is required with the length between 2-100 characters')
			.when(model => !!model.other);
	}
}

export class DisableUserModelValidator extends ExtraValidator<DisableUserModel> {
	constructor() {
		super();

		this.ruleFor('endDate')
			.must(endDate => !!endDate)
			.withMessage('End Date is required!');
	}
}

export class ResetPasswordModelValidator extends ExtraValidator<ChangeUserPasswordModel> {
	constructor(settings: PasswordSettingsModel) {
		super();

		this.ruleFor('password')
			.matches(new RegExp('^.{' + settings.MinimumLength + ',' + settings.MaximumLength + '}$'))
			.withMessage(`Length must be between ${settings.MinimumLength}-${settings.MaximumLength} characters!`)
			.matches(new RegExp('^(?:[^\\d]*\\d){' + settings.MinimumNumericCharacters + ',}'))
			.withMessage(`Minimum digits required: ${settings.MinimumNumericCharacters}`)
			.matches(new RegExp('^(?:[^a-z]*[a-z]){' + settings.MinimumLowercaseCharacters + ',}'))
			.withMessage(`Minimum lowercase letters: ${settings.MinimumLowercaseCharacters}`)
			.matches(new RegExp('^(?:[^A-Z]*[A-Z]){' + settings.MinimumUppercaseCharacters + ',}'))
			.withMessage(`Minimum uppercase letters: ${settings.MinimumUppercaseCharacters}`)
			.matches(new RegExp('^(?:[^' + settings.SpecialCharacters.replace('-', '\\-') + ']*[' + settings.SpecialCharacters.replace('-', '\\-') + ']){' + settings.MinimumSpecialCharacters + ',}'))
			.withMessage(`Minimum special characters: ${settings.MinimumSpecialCharacters} from ${settings.SpecialCharacters}`)
			.when(model => model.password !== null);

		this.ruleFor('email')
			.must(email => !!email)
			.withMessage('Email is required')
			.emailAddress();
	}
}
export class UserLookupDtoValidator extends ExtraValidator<UserLookupDto> {
	constructor() {
		super();

		this.ruleFor('userName')
			.must(userName => !!userName)
			.withMessage('Username is required');
	}
}

