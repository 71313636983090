import { Component, OnInit, ViewChild } from '@angular/core';
import { createProxy, toast } from '@nstep-common/utils';
import { ModalComponent } from '@nstep-common/semantic-ui';
import { BaseComponent, ColumnSortation, PagedQueryParameter, PaginationModel, TableColumn } from '@nstep-common/core';
import { ValidationErrors } from 'fluentvalidation-ts';
import { chain, flatten } from 'lodash';
import { EntitlementTypeModel, EntitlementTypeModelValidator } from '@nstep-internal/pages';
import { EntitlementTypeService } from '@nstep-internal/shared';

@Component({
	selector: 'app-entitlement-types',
	templateUrl: './entitlement-types.component.html'
})
export class EntitlementTypesComponent extends BaseComponent implements OnInit {
	@ViewChild('entitlementTypesModal') entitlementTypesModal!: ModalComponent;
	@ViewChild('entitlementTypeConfimationModal') entitlementTypeConfimationModal!: ModalComponent;

	tableData: any[] = [];
	tableDataReady = false;

	editModal: boolean = false;
	action: string = '';

	modalIsLoading = false;

	errors: string[] = [];

	tableColumns: TableColumn[] = [
		{ name: 'Name', key: 'name', sortAsc: true, isCellCentered: true, isHeaderCentered: true },
		{ name: 'Rationed', key: 'rationingTypeId', isCellCentered: true, isHeaderCentered: true },
		{ name: 'Min Buy Amount', key: 'minBuyAmount', isCellCentered: true, isHeaderCentered: true },
		{ name: 'Action', isCellCentered: true, isHeaderCentered: true }
	];

	pagedQueryModel = new PagedQueryParameter({
		itemsPerPage: 10,
		page: 1,
		orderField: 'name',
		searchBy: '',
		isMultiWordSerch: false
	});

	pagination = new PaginationModel();

	validation: ValidationErrors<EntitlementTypeModel> = {};
	isValid: boolean = false;

	entitlementType: EntitlementTypeModel = createProxy(new EntitlementTypeModel(), {
		set: () => this.validate(this.entitlementType)
	});

	validate(value: EntitlementTypeModel): void {
		const validator = new EntitlementTypeModelValidator();
		this.validation = validator.validate(value);
		this.isValid = Object.keys(this.validation).length === 0;
	}

	constructor(private entitlementTypeService: EntitlementTypeService) {
		super();
	}

	ngOnInit(): void {
		super.ngOnInit();

		this.initializeTable();
	}

	initializeTable(): void {
		this.subscriptions.push(
			this.entitlementTypeService.getEntitlementTypes(this.pagedQueryModel).subscribe({
				next: response => {

					this.pagination = response.page;
					this.tableData = chain(response.results)
						.map(e => ({
							id: e.id,
							name: e.name,
							rationed: e.rationingTypeId === 1,
							rationingTypeId: e.rationingTypeId,
							minBuyAmount: e.minBuyAmount,
							disabled: e.disabled,
							action: e.disabled ? 'Enable' : 'Disable'
						}))
						.value();

					this.tableDataReady = true;
					this.modalIsLoading = false;
				},
				error: () => {
					this.tableDataReady = true;
					this.modalIsLoading = false;
				}
			})
		);
	}

	openAddTypeModal(): void {
		this.editModal = false;
		this.entitlementType = createProxy(new EntitlementTypeModel(), {
			set: () => this.validate(this.entitlementType)
		});

		this.validate(this.entitlementType);

		this.entitlementTypesModal.toggle();
	}

	search(): void {
		this.pagedQueryModel.page = 1;
		this.initializeTable();
	}

	onUserAction(item: any, action: string): void {
		this.editModal = false;
		this.action = action;

		const selectedItem = new EntitlementTypeModel({
			id: item.id,
			name: item.name,
			rationingTypeId: item.rationingTypeId,
			rationed: item.rationingTypeId === 1,
			disabled: item.disabled,
			minBuyAmount: item.minBuyAmount
		});

		this.entitlementType = createProxy(selectedItem, {
			set: () => this.validate(this.entitlementType)
		});

		if (this.action === 'Edit') {
			this.editModal = true;
			this.validate(this.entitlementType);
			this.entitlementTypesModal.toggle();

			return;
		}

		this.entitlementTypeConfimationModal.toggle();
	}

	enableOrDisable() {
		this.errors = [];
		this.modalIsLoading = true;

		if (this.action === 'Enable') {
			this.subscriptions.push(
				this.entitlementTypeService.enableEntitlementType(this.entitlementType.id).subscribe({
					next: () => {
						this.tableDataReady = false;
						this.tableData = [];

						this.entitlementTypeService.clearGetEntitlementTypesCache();
						this.initializeTable();

						toast('Success', `Entitlement Type ${this.entitlementType.name} successfully enabled!`, 'green');
						this.entitlementTypeConfimationModal.toggle();
					},
					error: () => {
						this.modalIsLoading = false;
						toast('Error', `Entitlement Type ${this.entitlementType.name} could not be enabled!`, 'red');
					}
				})
			);
		} else {
			this.subscriptions.push(
				this.entitlementTypeService.disableEntitlementType(this.entitlementType.id).subscribe({
					next: () => {
						this.tableDataReady = false;
						this.tableData = [];

						this.entitlementTypeService.clearGetEntitlementTypesCache();
						this.initializeTable();

						toast('Success', `Entitlement Type ${this.entitlementType.name} successfully disabled!`, 'green');
						this.entitlementTypeConfimationModal.toggle();
					},
					error: () => {
						this.modalIsLoading = false;
						toast('Error', `Entitlement Type ${this.entitlementType.name} could not be disabled!`, 'red');
					}
				})
			);
		}
	}

	close(modal: string): void {
		switch (modal) {
			case 'entitlementTypesModal':
				this.entitlementTypesModal.toggle();
				break;
			case 'entitlementTypeConfimationModal':
				this.entitlementTypeConfimationModal.toggle();
				break;
		}

		this.errors = [];
	}

	save(): void {
		this.errors = [];
		this.modalIsLoading = true;

		if (this.editModal) {
			this.subscriptions.push(
				this.entitlementTypeService.updateEntitlementType(this.entitlementType).subscribe({
					next: () => {
						this.tableDataReady = false;
						this.tableData = [];

						this.entitlementTypeService.clearGetEntitlementTypesCache();
						this.initializeTable();

						toast('Success', `Entitlement Type ${this.entitlementType.name} successfully modified!`, 'green');
						this.entitlementTypesModal.toggle();
					},
					error: (response) => {
						this.modalIsLoading = false;

						toast('Error', `Entitlement Type ${this.entitlementType.name} could not be modified!`, 'red');
						this.errors = flatten(Object.values(response));
					}
				})
			);
		} else {
			this.subscriptions.push(
				this.entitlementTypeService.createEntitlementType(this.entitlementType).subscribe({
					next: () => {
						this.tableDataReady = false;
						this.tableData = [];

						this.entitlementTypeService.clearGetEntitlementTypesCache();
						this.initializeTable();

						toast('Success', `Entitlement Type ${this.entitlementType.name} saved!`, 'green');
						this.entitlementTypesModal.toggle();
					},
					error: (response) => {
						this.modalIsLoading = false;

						toast('Error', `Entitlement Type ${this.entitlementType.name} could not be saved!`, 'red');
						this.errors = flatten(Object.values(response));
					}
				})
			);
		}
	}

	pageChange(page: PaginationModel): void {
		this.pagedQueryModel.page = page.currentPage;
		this.pagedQueryModel.itemsPerPage = page.pageSize;

		this.tableDataReady = false;
		this.tableData = [];

		this.initializeTable();
	}

	sortChange(col: ColumnSortation): void {
		this.pagedQueryModel.page = 1;
		this.pagedQueryModel.orderField = col.sortAsc !== null ? `${col.key} ${col.sortAsc ? 'asc' : 'desc'}` : '';

		this.tableDataReady = false;
		this.tableData = [];

		this.initializeTable();
	}
}
