import { Component, OnInit, ViewChild } from '@angular/core';
import { createProxy, toast } from '@nstep-common/utils';
import { BaseComponent, ColumnSortation, PagedQueryParameter, PaginationModel, PasswordSettingsModel, RoleDto, SettingsService, TableColumn } from '@nstep-common/core';
import { DropdownOption, ModalComponent } from '@nstep-common/semantic-ui';
import { ValidationErrors } from 'fluentvalidation-ts';
import { chain, flatten, map } from 'lodash';

import {
	DisableUserModel,
	DisableUserModelValidator,
	HeadquarterWithNationDto,
	NationWithHqDtos,
	OperatorCardDto,
	ChangeUserPasswordModel,
	ResetPasswordModelValidator,
	SubRoleDto,
	Toggle2FModel,
	ToggleLockModel,
	UserDto,
	UserLookupDto,
	UserLookupDtoValidator,
	UserModel,
	UserModelValidator,
	UserRoles
} from '@nstep-internal/pages';

import { UserService, PosLocationService, NationService } from '@nstep-internal/shared';

@Component({
	selector: 'app-users',
	templateUrl: './users.component.html'
})
export class UsersComponent extends BaseComponent implements OnInit {
	@ViewChild('resetTwoFactorModal') resetTwoFactorModal!: ModalComponent;
	@ViewChild('userModal') userModal!: ModalComponent;
	@ViewChild('enableUserConfimationModal') enableUserConfimationModal!: ModalComponent;
	@ViewChild('disableUserConfimationModal') disableUserConfimationModal!: ModalComponent;
	@ViewChild('resetPasswordModal') resetPasswordModal!: ModalComponent;

	errors: string[] = [];

	modalIsLoading = false;

	tableData: any[] = [];
	tableDataReady = false;

	today = new Date(new Date().toDateString());

	calendarSettings = {
		type: 'date',
		minDate: this.today
	};

	searchCardResult: OperatorCardDto | null = null;
	searchCard: (term: string, callback: (results: {
		obj: any;
		description: string;
		title: string;
	}[]) => void) => void = (term, callback) => {
		const getAmisCards$ = this.posLocationService.getOperatorCards(term)
			.subscribe(resp => {
				const results = map(resp, item => ({
					obj: item,
					description: '',
					title: item.cardNumber
				}));

				callback(results);
			});

		this.subscriptions.push(getAmisCards$);
	};

	searchUserResult: UserLookupDto | null = null;
	searchUser: (term: string, callback: (results: {
		obj: any;
		description: string;
		title: string;
	}[]) => void) => void = (term, callback) => {
		const getUsersBy2FEnabled$ = this.userService.getUsersBy2FEnabled(term)
			.subscribe(resp => {
				const results = map(resp, item => ({
					obj: item,
					description: '',
					title: item.userName
				}));

				callback(results);
			});

		this.subscriptions.push(getUsersBy2FEnabled$);
	};

	tableColumns: TableColumn[] = [
		{ name: 'Username', key: 'userName', sortAsc: true, isHeaderCentered: true, isCellCentered: true },
		{ name: 'Name', key: 'name', isHeaderCentered: true, isCellCentered: true },
		{ name: 'Country', key: 'nation.nationName', isHeaderCentered: true, isCellCentered: true },
		{ name: 'Type', key: 'role.roleName', isHeaderCentered: true, isCellCentered: true },
		{ name: 'Telephone', key: 'telephone', isHeaderCentered: true, isCellCentered: true },
		{ name: 'Other', key: 'other', isHeaderCentered: true, isCellCentered: true },
		{ name: 'Start Date', key: 'startDate', isHeaderCentered: true, isCellCentered: true },
		{ name: 'End Date', key: 'endDate', isHeaderCentered: true, isCellCentered: true },
		//{ name: 'Has Two Factor', isHeaderCentered: true, isCellCentered: true },
		{ name: 'Is Locked', isHeaderCentered: true, isCellCentered: true },
		{ name: 'Actions', isHeaderCentered: true, isCellCentered: true },
	];

	roleDropdownValues: DropdownOption[] = [];
	roleDropdownDataReady = false;

	subRoleDropdownValues: DropdownOption[] = [];
	subRoleDropdownDataReady = false;


	countryDropdownValues: DropdownOption[] = [];
	countryDropdownDataReady = false;

	headquartersDropdownValues: DropdownOption[] = [];

	roles!: RoleDto[];
	subRoles!: SubRoleDto[];
	nations!: NationWithHqDtos[];
	selectedUser: UserDto = {} as UserDto;
	passwordSettings: PasswordSettingsModel = new PasswordSettingsModel();

	passwordType: string = 'auto';
	editModal: boolean = false;

	validation: ValidationErrors<UserDto> = {};
	disableValidation: ValidationErrors<DisableUserModel> = {};
	passwordResetValidation: ValidationErrors<ChangeUserPasswordModel> = {};
	userLookupValidation: ValidationErrors<UserLookupDto> = {};
	isValid: boolean = false;

	userModel: UserModel = createProxy(new UserModel(), {
		set: () => this.validate(this.userModel)
	});

	validate(value: UserModel): void {
		const validator = new UserModelValidator(this.passwordSettings);
		this.validation = validator.validate(value);
		this.isValid = Object.keys(this.validation).length === 0;
	}

	disableUserModel: DisableUserModel = createProxy(new DisableUserModel(), {
		set: () => this.validateDisableUser(this.disableUserModel)
	});

	validateDisableUser(value: DisableUserModel): void {
		const validator = new DisableUserModelValidator();
		this.disableValidation = validator.validate(value);
		this.isValid = Object.keys(this.disableValidation).length === 0;
	}

	resetPasswordModel: ChangeUserPasswordModel = createProxy(new ChangeUserPasswordModel(), {
		set: () => this.validateResetPassword(this.resetPasswordModel)
	});

	validateResetPassword(value: ChangeUserPasswordModel): void {
		const validator = new ResetPasswordModelValidator(this.passwordSettings);
		this.passwordResetValidation = validator.validate(value);
		this.isValid = Object.keys(this.passwordResetValidation).length === 0;
	}

	userLookupModel: UserLookupDto = createProxy(new UserLookupDto(), {
		set: () => this.validateUserLookup(this.userLookupModel)
	});

	validateUserLookup(value: UserLookupDto): void {
		const validator = new UserLookupDtoValidator();
		this.userLookupValidation = validator.validate(value);
		this.isValid = Object.keys(this.userLookupValidation).length === 0;
	}

	pagedQueryModel = new PagedQueryParameter({
		itemsPerPage: 10,
		page: 1,
		orderField: 'userName',
		searchBy: '',
		isMultiWordSerch: false
	});

	pagination = new PaginationModel();

	constructor(private userService: UserService,
		private nationService: NationService,
		private posLocationService: PosLocationService,
		private settingsService: SettingsService) {
		super();
	}

	ngOnInit(): void {
		super.ngOnInit();

		this.initializeTable();

		this.subscriptions.push(
			this.settingsService.getPasswordSettings().subscribe(s => {
				this.passwordSettings = s;
			})
		);

		this.subscriptions.push(
			this.userService.getRoles().subscribe({
				next: response => {
					this.roles = response;

					this.roleDropdownValues = chain(this.roles)
						.map(e => new DropdownOption({
							value: e.roleId,
							name: e.roleName
						}))
						.orderBy(e => e.name)
						.value();

					this.roleDropdownDataReady = true;
				},
				error: () => {
					this.roleDropdownDataReady = true;
				}
			})
		);

		this.subscriptions.push(
			this.nationService.getNations().subscribe({
				next: response => {
					this.nations = response;

					this.countryDropdownValues = chain(this.nations)
						.map(e => new DropdownOption({
							value: e.id,
							name: e.nationName
						}))
						.orderBy(e => e.name)
						.value();

					this.countryDropdownDataReady = true;
				},
				error: () => {
					this.countryDropdownDataReady = true;
				}
			})
		);

		this.subscriptions.push(
			this.userService.getSubRoles().subscribe({
				next: response => {
					this.subRoles = response;

					this.subRoleDropdownValues = chain(this.subRoles)
						.map(e => new DropdownOption({
							value: e.id,
							name: e.name
						}))
						.orderBy(e => e.name)
						.value();

					this.subRoleDropdownDataReady = true;
				},
				error: () => {
					this.subRoleDropdownDataReady = true;
				}
			})
		);
	}

	initializeTable(): void {
		this.tableDataReady = false;
		this.tableData = [];

		this.subscriptions.push(
			this.userService.getUsers(this.pagedQueryModel)
				.subscribe({
					next: response => {

						const dateNow = new Date();
						const today = new Date(new Date().toDateString());

						this.pagination = response.page;
						this.tableData = chain(response.results)
							.map(e => {
								return {
									id: e.id,
									userName: e.userName,
									name: e.name,
									email: e.email,
									telephone: e.telephone,
									other: e.other,
									startDate: e.startDate,
									endDate: e.endDate,
									nationId: e.nationId,
									disabled: e.endDate ? new Date(new Date(e.endDate).toDateString()) < today : false,
									resetted: e.resetted,
									cardOperatorId: e.cardOperatorId,
									nation: e.nation?.nationName,
									type: e.role?.roleName,
									card: e.card,
									headquarters: e.headquarters,
									subRoles: e.subRoles,
									actions: e.endDate ? e.startDate!.getTime() <= dateNow.getTime() && dateNow.getTime() <= e.endDate.getTime() ? ['Disable', 'Reset Password'] : ['Enable', 'Reset Password'] : ['Disable', 'Reset Password'],
									requires2F: e.requires2F,
									isHardLocked: e.isHardLocked
								};
							})
							.value();

						this.tableDataReady = true;
						this.modalIsLoading = false;
					},
					error: () => {
						this.tableDataReady = true;
						this.modalIsLoading = false;
					}
				})
		);
	}

	openResetTwoFactorModal(): void {
		this.searchUserResult = null;
		this.userLookupModel.userName = '';
		this.resetTwoFactorModal.toggle();
	}

	resetTwoFactor(): void {
		this.modalIsLoading = true;

		const resetTwoFactor$ = this.userService.reset2F(this.userLookupModel.id).subscribe({
			next: () => {
				this.modalIsLoading = false;

				toast('Success', `Two Factor for user ${this.userLookupModel.userName} successfully disabled!`, 'green');
				this.resetTwoFactorModal.toggle();
			},
			error: () => {
				this.modalIsLoading = false;

				toast('Error', `Two Factor disabling for user ${this.userLookupModel.userName} encountered a problem!`, 'red');
			}
		})

		this.subscriptions.push(resetTwoFactor$);
	}

	openUserModal(): void {
		this.editModal = false;

		this.userModel.id = null;
		this.userModel.roleId = UserRoles.HostNation;
		this.userModel.subRolesIds = [];
		this.userModel.userName = null;
		this.userModel.password = null;
		this.userModel.name = null;
		this.userModel.email = null;
		this.userModel.telephone = null;
		this.userModel.other = null;
		this.userModel.startDate = null;
		this.userModel.endDate = null;
		this.userModel.nationId = null;
		this.userModel.resetted = false;
		this.userModel.cardOperatorId = null;
		this.userModel.cardOperatorNumber = null;
		this.userModel.headquartersIds = [];

		this.searchCardResult = null;

		this.userModal.toggle();
	}

	search(): void {
		this.pagedQueryModel.page = 1;
		this.initializeTable();
	}

	changePasswordType(type: string, modal: string): void {

		if (this.passwordType === type) return;

		this.passwordType = type;

		switch (modal) {
			case 'userModal':
				this.userModel.password = type === 'typed' ? '' : null;
				break;
			case 'resetPasswordModal':
				this.resetPasswordModel.password = type === 'typed' ? '' : null;
				break;
		}
	}

	close(modal: string): void {
		switch (modal) {
			case 'userModal':
				this.userModal.toggle();
				this.passwordType = 'auto';
				break;
			case 'enableUserConfimationModal':
				this.enableUserConfimationModal.toggle();
				break;
			case 'disableUserConfimationModal':
				this.disableUserConfimationModal.toggle();
				break;
			case 'resetPasswordModal':
				this.resetPasswordModal.toggle();
				this.passwordType = 'auto';
				break;
			case 'resetTwoFactorModal':
				this.resetTwoFactorModal.toggle();
				break;
		}

		this.errors = [];
	}

	save(): void {
		this.errors = [];
		this.modalIsLoading = true;

		const selectedRole = this.roles.find(r => r.roleId === this.userModel.roleId!);

		const userModel = new UserDto({
			id: this.userModel.id,
			role: new RoleDto({
				roleId: this.userModel.roleId!,
				roleName: selectedRole ? selectedRole.roleName : null
			}),
			subRoles: this.userModel.subRolesIds.map(srId => new SubRoleDto({ id: srId, roleId: this.userModel.roleId! })),
			userName: this.userModel.userName!,
			password: this.userModel.password,
			name: this.userModel.name!,
			email: this.userModel.email!,
			telephone: this.userModel.telephone!,
			other: this.userModel.other!,
			startDate: this.userModel.startDate,
			endDate: this.userModel.endDate,
			nationId: this.userModel.nationId,
			cardOperatorId: this.userModel.cardOperatorId,
			headquarters: this.userModel.headquartersIds.map(hqId => new HeadquarterWithNationDto({ id: hqId }))
		});

		if (this.editModal) {
			this.subscriptions.push(
				this.userService.updateUser(userModel).subscribe({
					next: () => {
						this.userService.clearGetUsersCache();
						this.initializeTable();
						toast('Success', `User ${this.userModel.userName} successfully modified!`, 'green');

						this.userModal.toggle();
					},
					error: (response: { [key: string]: string[] }) => {
						this.modalIsLoading = false;

						toast('Error', `User ${this.userModel.userName} could not be modified!`, 'red');
						this.errors = flatten(Object.values(response));
					}
				})
			);
		} else {
			this.subscriptions.push(
				this.userService.createUser(userModel).subscribe({
					next: () => {
						this.userService.clearGetUsersCache();
						this.initializeTable();
						toast('Success', `User ${this.userModel.userName} successfully created!`, 'green');

						this.userModal.toggle();
					},
					error: (response: { [key: string]: string[] }) => {
						this.modalIsLoading = false;

						toast('Error', `User ${this.userModel.userName} could not be created!`, 'red');
						this.errors = flatten(Object.values(response));
					}
				})
			);
		}
	}

	onUserAction(item: UserDto, action: string): void {
		this.editModal = false;
		this.selectedUser = item;

		if (action === 'Edit') {
			this.editModal = true;
			this.subscriptions.push(
				this.userService.getUser(item.id!).subscribe({
					next: response => {

						this.userModel.id = response.id;
						this.userModel.roleId = response.role.roleId;
						this.userModel.subRolesIds = response.subRoles.flatMap(sr => sr.id);
						this.userModel.userName = response.userName;
						this.userModel.password = response.password;
						this.userModel.name = response.name;
						this.userModel.email = response.email;
						this.userModel.telephone = response.telephone;
						this.userModel.other = response.other;
						this.userModel.startDate = response.startDate ? new Date(response.startDate.toDateString()) : null;
						this.userModel.endDate = response.endDate ? new Date(response.endDate.toDateString()) : null;
						this.userModel.nationId = response.nationId;
						this.userModel.resetted = response.resetted;
						this.userModel.cardOperatorId = response.cardOperatorId;
						this.userModel.cardOperatorNumber = response.card ? response.card.cardNumber : null;

						this.searchCardResult = null;

						this.populateHeadquarterOptions();
						this.userModel.headquartersIds = response.headquarters.flatMap(h => h.id);

						this.validate(this.userModel);

						this.userService.clearGetUserCache(item.id!);
						this.userModal.toggle();
					},
					error: () => {
						this.userService.clearGetUserCache(item.id!);
					}
				})
			);

			return;
		}

		if (action === 'Reset Password') {
			this.subscriptions.push(
				this.userService.getUser(item.id!).subscribe({
					next: response => {

						this.resetPasswordModel = createProxy(new ChangeUserPasswordModel(), {
							set: () => this.validateResetPassword(this.resetPasswordModel)
						});

						this.resetPasswordModel.userId = response.id!;
						this.resetPasswordModel.email = response.email;

						this.userService.clearGetUserCache(item.id!);
						this.resetPasswordModal.toggle();
					},
					error: () => {
						this.userService.clearGetUserCache(item.id!);
					}
				})
			);

			return;
		}

		this.openConfirmationModal(action);
	}

	onUserResultChange(result: UserLookupDto): void {
		this.searchUserResult = result;
		this.userLookupModel.id = result.id;
		this.userLookupModel.userName = result.userName;
	}

	onAmisCardResultChange(result: OperatorCardDto): void {
		this.searchCardResult = result;
		this.userModel.cardOperatorId = result.id;
		this.userModel.cardOperatorNumber = result.cardNumber;
	}

	openConfirmationModal(action: string): void {

		this.disableUserModel = createProxy(new DisableUserModel(), {
			set: () => this.validateDisableUser(this.disableUserModel)
		});

		switch (action) {
			case 'Enable':
				this.enableUserConfimationModal.toggle();
				break;
			case 'Disable':
				this.validateDisableUser(this.disableUserModel);
				this.disableUserConfimationModal.toggle();
				break;
		}
	}

	toggle2F(userId: number, requires2F: boolean): void {
		this.subscriptions.push(this.userService
			.toggle2F(new Toggle2FModel({
				requires2F: requires2F,
				userId: userId
			}))
			.subscribe(() => {
				toast('Success', `User two factor has been ${requires2F ? 'enabled' : 'disabled'}!`, 'green');
			}));
	}

	toggleLock(userId: number, isHardLocked: boolean): void {
		this.subscriptions.push(this.userService
			.toggleLock(new ToggleLockModel({
				isHardLocked: isHardLocked,
				userId: userId
			}))
			.subscribe(() => {
				toast('Success', `User has been ${isHardLocked ? 'locked' : 'unlocked'}!`, 'green');
			}));
	}

	enableOrDisable(action: string | null): void {
		this.disableUserModel.userId = this.selectedUser.id!;
		this.errors = [];
		this.modalIsLoading = true;

		this.subscriptions.push(
			this.userService.disableUser(this.disableUserModel).subscribe({
				next: () => {
					this.userService.clearGetUsersCache();
					this.initializeTable();
					if (action === 'Disable') {
						toast('Success', `User ${this.selectedUser.userName} successfully disabled!`, 'green');
						this.disableUserConfimationModal.toggle();
					} else {
						toast('Success', `User ${this.selectedUser.userName} successfully enabled!`, 'green');
						this.enableUserConfimationModal.toggle();
					}

				},
				error: (response) => {
					this.modalIsLoading = false;

					if (action === 'Disable') {
						toast('Error', `User ${this.selectedUser.userName} disabling encountered a problem!`, 'red');
						//this.disableUserConfimationModal.toggle();
					} else {
						toast('Error', `User ${this.selectedUser.userName} enabling encountered a problem!`, 'red');
						//this.enableUserConfimationModal.toggle();
					}

					this.errors = flatten(Object.values(response));
				}
			})
		);
	}

	resetPassword(): void {
		this.errors = [];
		this.modalIsLoading = true;

		this.subscriptions.push(
			this.userService.changeUserPassword(this.resetPasswordModel).subscribe({
				next: () => {
					this.modalIsLoading = false;

					toast('Success', `Password succcessfully reset for user ${this.selectedUser.userName}!`, 'green');
					this.resetPasswordModal.toggle();
				},
				error: (response) => {
					this.modalIsLoading = false;

					toast('Error', `"Error resetting password for user ${this.selectedUser.userName}!`, 'red');
					this.errors = flatten(Object.values(response));
				}
			})
		);
	}

	onCountryValueChange(): void {
		this.populateHeadquarterOptions();
		this.userModel.headquartersIds = [];
	}

	private populateHeadquarterOptions(): void {
		const currentNation = this.nations.find(n => n.id === this.userModel.nationId)!;

		if (currentNation) {
			this.headquartersDropdownValues = chain(currentNation.headquarters)
				.map(e => new DropdownOption({
					value: e.id,
					name: e.name
				}))
				.orderBy(e => e.name)
				.value();
		}
		else {
			this.headquartersDropdownValues = [];
		}
	}

	onRoleValueChange(): void {
		this.userModel.subRolesIds = [];
	}

	pageChange(page: PaginationModel): void {
		this.pagedQueryModel.page = page.currentPage;
		this.pagedQueryModel.itemsPerPage = page.pageSize;

		this.initializeTable();
	}

	sortChange(col: ColumnSortation): void {
		this.pagedQueryModel.page = 1;
		this.pagedQueryModel.orderField = col.sortAsc !== null ? `${col.key} ${col.sortAsc ? 'asc' : 'desc'}` : '';

		this.tableDataReady = false;
		this.tableData = [];

		this.initializeTable();
	}
}
